import { FrownOutlined } from "@ant-design/icons";
import { notification } from "antd";

async function useHandlePropertyFloorUpdate(
  formValues,
  floorId,
  updatePropertyFloorMutation,
  assignMultipleSystemsToFloorMutation,
  setIsFormDirty,
  setIsLoading,
) {
  let updatePropertyFloorResponse;
  let assignMultipleSystemsToFloorResponse;

  const updatePropertyFloorDto = {
    Name: formValues.Name,
  };

  const assignMultipleSystemToFloorRequest = {
    AssignedSystems: formValues?.Systems.AssignedSystems.map((x) => {
      return x.UniqueId;
    }),
    UnassignedSystems: formValues?.Systems.UnassignedSystems.map((x) => {
      return x.UniqueId;
    }),
  };

  try {
    // Floor Name Update
    updatePropertyFloorResponse = await updatePropertyFloorMutation.mutateAsync(
      {
        floorId,
        request: updatePropertyFloorDto,
      },
    );

    // System Assignment/Unassignment
    if (
      assignMultipleSystemToFloorRequest.AssignedSystems.length > 0 ||
      assignMultipleSystemToFloorRequest.UnassignedSystems.length > 0
    ) {
      assignMultipleSystemsToFloorResponse =
        await assignMultipleSystemsToFloorMutation.mutateAsync({
          floorId,
          request: assignMultipleSystemToFloorRequest,
        });
    }
  } catch (error) {
    notification.error({
      message: (
        <span className="uppercase tracking-widest">Something went wrong!</span>
      ),
      duration: 5,
      description: "Could not update floor.",
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  } finally {
    setIsLoading(false);
    setIsFormDirty(false);
    notification.success({
      message: (
        <span className="uppercase tracking-widest">floor update success</span>
      ),
      duration: 2,
      placement: "topRight",
    });
  }

  return { updatePropertyFloorResponse, assignMultipleSystemsToFloorResponse };
}

export default useHandlePropertyFloorUpdate;
