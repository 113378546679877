import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import usePropertyById from "../../../api/hooks/usePropertyById";
import "./StatisticsPropertyOverview.scss";
import Loader from "../../Loader/Loader";

function StatisticsPropertyOverview({ propertyOverview }) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: property,
    status,
    refetch: getPropertyById,
  } = usePropertyById(propertyOverview?.PropertyId, {
    enabled: false,
    cacheTime: 0,
  });

  useEffect(() => {
    if (propertyOverview && propertyOverview.PropertyId !== "all") {
      setIsLoading(true);
      getPropertyById(propertyOverview.PropertyId).then(() => {
        setIsLoading(false);
      });
    }
  }, [propertyOverview, getPropertyById]);
  return (
    <div className="statistics-property-overview">
      <div className="statistics-property-details pb-10">
        <h1 className="statistics-property-title page-title">
          {/* eslint-disable-next-line no-nested-ternary */}
          {status === "loading" && isLoading === true ? (
            <Loader />
          ) : propertyOverview && propertyOverview?.PropertyId !== "all" ? (
            property?.Name
          ) : (
            "All"
          )}
        </h1>
        <h3 className="statistics-property-address">{property?.Address}</h3>
      </div>
    </div>
  );
}
StatisticsPropertyOverview.defaultProps = {
  propertyOverview: PropTypes.object,
};
StatisticsPropertyOverview.propTypes = {
  propertyOverview: PropTypes.object,
};
export default StatisticsPropertyOverview;
