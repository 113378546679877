import { useEffect, useState } from "react";

import { Select, Space } from "antd";
import PropTypes from "prop-types";

import useFloorsByPropertyId from "../../../api/hooks/useFloorsByPropertyId";
import usePropertyFloorById from "../../../api/hooks/usePropertyFloorById";
import useWaterConsuptionFilters from "../../../api/hooks/useWaterConsuptionFilters";
import "./StatisticsFilters.scss";

function StatisticsFilters({ handleOnChangeFilterCallback }) {
  const [floorSelectDisabled, setFloorSelectDisabled] = useState(true);
  const [systemSelectDisabled, setSystemSelectDisabled] = useState(true);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [selectedFloorId, setSelectedFloorId] = useState(null);
  const [selectedSystemId, setSelectedSystemId] = useState(null);
  const propertyOptions = [{ value: "all", label: "All Properties" }];
  const floorOptions = [{ value: "all", label: "All Floors" }];
  const systemOptions = [{ value: "all", label: "All Systems" }];

  // Hooks
  const { data: propertiesObject } = useWaterConsuptionFilters();
  const { data: property, refetch: getFloorsByPropertyId } =
    useFloorsByPropertyId(
      { propertyId: selectedPropertyId?.PropertyId },
      {
        enabled: false,
        cacheTime: 0,
      },
    );
  const { data: floor, refetch: getSelectedFloor } = usePropertyFloorById(
    selectedFloorId,
    { enabled: false, cacheTime: 0 },
  );

  // Configure the select dropdowns with values
  if (propertiesObject && propertiesObject.Properties) {
    propertiesObject.Properties.forEach((p) =>
      propertyOptions.push({ value: p.Id, label: p.Name }),
    );
  }

  if (property) {
    property?.Floors?.forEach((f) =>
      floorOptions.push({ value: f.Id, label: f.Name }),
    );

    if (floor) {
      floor?.Systems?.forEach((s) =>
        systemOptions.push({ value: s.Id, label: s.Name }),
      );
    } else if (selectedFloorId === "all") {
      property?.Floors?.forEach((floor) => {
        floor.Systems.map((fs) =>
          systemOptions.push({ value: fs.Id, label: fs.Name }),
        );
      });
    }
  }

  // Effects
  useEffect(() => {
    if (selectedPropertyId && selectedPropertyId.PropertyId !== "all") {
      getFloorsByPropertyId();
    }
  }, [selectedPropertyId, getFloorsByPropertyId]);

  useEffect(() => {
    if (selectedFloorId && selectedFloorId !== "all") {
      getSelectedFloor(selectedFloorId);
    }
  }, [selectedFloorId, getSelectedFloor]);

  useEffect(() => {
    handleOnChangeFilterCallback(
      selectedPropertyId,
      selectedFloorId,
      selectedSystemId,
    );
  });

  const onChangeProperty = (value) => {
    if (value === "all") {
      setSelectedPropertyId({ PropertyId: "all" });
      setSelectedFloorId(null);
      setSelectedSystemId(null);
      setFloorSelectDisabled(true);
      setSystemSelectDisabled(true);
    } else {
      setSelectedPropertyId({ PropertyId: value });
      setFloorSelectDisabled(false);
      setSelectedFloorId("all");
      setSystemSelectDisabled(false);
      setSelectedSystemId("all");
    }
  };

  const onChangeFloor = (value) => {
    if (value !== "all") {
      setSelectedFloorId(value);
    } else {
      setSelectedFloorId("all");
    }
    setSelectedSystemId("all");
  };

  const onChangeSystem = (value) => {
    if (value !== "all") {
      setSelectedSystemId(value);
    } else {
      setSelectedSystemId("all");
    }
  };

  const filterSelect = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="statistics-filters">
      <Space direction="horizontal" wrap>
        <Select
          dropdownStyle={{ minWidth: "130px" }}
          showSearch
          placeholder="All Properties"
          optionFilterProp="property"
          onChange={onChangeProperty}
          filterOption={filterSelect}
          className="property-selector"
          defaultValue="all"
          options={propertyOptions}
        />
        <Select
          dropdownStyle={{ minWidth: "130px" }}
          showSearch
          placeholder="All Floors"
          optionFilterProp="propertyFloor"
          onChange={onChangeFloor}
          filterOption={filterSelect}
          className="floor-selector"
          disabled={floorSelectDisabled}
          value={selectedFloorId}
          options={floorOptions}
        />
        <Select
          showSearch
          dropdownStyle={{ minWidth: "130px" }}
          placeholder="All Systems"
          optionFilterProp="propertySystem"
          onChange={onChangeSystem}
          filterOption={filterSelect}
          className="system-selector"
          disabled={systemSelectDisabled}
          value={selectedSystemId}
          options={systemOptions}
        />
      </Space>
    </div>
  );
}

StatisticsFilters.defaultProps = {
  handleOnChangeFilterCallback: () => {},
};
StatisticsFilters.propTypes = {
  handleOnChangeFilterCallback: PropTypes.func,
};

export default StatisticsFilters;
