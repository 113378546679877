function ProfileIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.557 13.928c-3.384 0-6.274.512-6.274 2.561 0 2.05 2.872 2.58 6.274 2.58 3.384 0 6.274-.513 6.274-2.562 0-2.048-2.871-2.579-6.274-2.579zM10.557 11.005a4.021 4.021 0 1 0-4.022-4.021 4.008 4.008 0 0 0 3.993 4.021h.029z"
        stroke="#E1E0E3"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="blue-stroke-color"
      />
    </svg>
  );
}

export default ProfileIcon;
