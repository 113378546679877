import { useEffect, useRef, useState } from "react";

import { Button, Form, Input } from "antd";
import PropTypes from "prop-types";

import useAssignMultipleSystemsToFloor from "../../../../../api/hooks/useAssignMultipleSystemsToFloor";
import usePropertyFloorById from "../../../../../api/hooks/usePropertyFloorById";
import useUpdatePropertyFloor from "../../../../../api/hooks/useUpdatePropertyFloor";
import useHandlePropertyFloorUpdate from "../hooks/useHandlePropertyFloorUpdate";
import FloorManagementFloorSystems from "./FloorManagementFloorSystems";

import "../scss/FloorManagementFloorEdit.scss";

function FloorManagementFloorEdit({
  floorId,
  setIsFormDirty,
  setEditFloorFormReference,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [editFormFloor] = Form.useForm();
  const initialFormValues = useRef({});

  const { data: floorObject, refetch: getFloorById } = usePropertyFloorById(
    floorId,
    { enabled: false },
  );
  // Mutation Fns
  const assignMultipleSystemsToFloorMutation =
    useAssignMultipleSystemsToFloor();
  const updatePropertyFloor = useUpdatePropertyFloor();

  useEffect(() => {
    getFloorById(floorId).then((response) => {
      const { data } = response;
      initialFormValues.current = {
        Name: data?.Name,
        Systems: { AssignedSystems: [], UnassignedSystems: [] },
      };
      editFormFloor.setFieldsValue(initialFormValues.current);
    });
  }, [editFormFloor, floorId, floorObject, getFloorById]);

  useEffect(() => {
    setEditFloorFormReference(editFormFloor);
  }, [editFormFloor, setEditFloorFormReference]);

  const handleValuesCahnges = (changedValues) => {
    const isFormDirty = Object.keys(changedValues).length > 0;
    setIsFormDirty(isFormDirty);
    setDisableSaveChanges(false);
  };

  const HandleUpdate = async (formValues) => {
    const {
      updatePropertyFloorResponse,
      assignMultipleSystemsToFloorResponse,
    } = await useHandlePropertyFloorUpdate(
      formValues,
      floorId,
      updatePropertyFloor,
      assignMultipleSystemsToFloorMutation,
      setIsFormDirty,
      setIsLoading,
    );

    const { AssignedSystems, UnassignedSystems } =
      assignMultipleSystemsToFloorResponse.data;

    editFormFloor.setFieldsValue({
      Name: updatePropertyFloorResponse?.data?.Name,
      Systems: {
        AssignedSystems,
        UnassignedSystems,
      },
    });

    return {
      updatePropertyFloorResponse,
      assignMultipleSystemsToFloorResponse,
    };
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const formValues = editFormFloor.getFieldsValue();
    await HandleUpdate(formValues);
    setDisableSaveChanges(true);
  };

  return (
    <div className="content-wrapper mx-2">
      <Form
        form={editFormFloor}
        name="floor-management-edit-floor"
        layout="vertical"
        className="w-full"
        initialValues={{
          Name: initialFormValues.current.Name,
          Systems: initialFormValues.current.Systems,
        }}
        onFinish={handleSubmit}
        onValuesChange={handleValuesCahnges}
      >
        <div className="flex items-center justify-center mb-0">
          <h1 className="page-title">Edit Floor</h1>
        </div>
        <Form.Item
          className="mb-4"
          label="Name"
          name="Name"
          rules={[{ required: true, message: "Name field is required." }]}
        >
          <Input size="middle" maxLength={64} />
        </Form.Item>
        <Form.Item name={["Systems", "AssignedSystems"]} hidden />
        <Form.Item name={["Systems", "UnassignedSystems"]} hidden />
        <FloorManagementFloorSystems
          editFloorFormReference={editFormFloor}
          floorId={floorId}
          setDisableSaveChanges={setDisableSaveChanges}
          setIsFormDirty={setIsFormDirty}
        />
        <Button
          type="primary"
          htmlType="submit"
          size="medium"
          className="custom-property-save-btn"
          loading={isLoading}
          disabled={disableSaveChanges}
        >
          Save
        </Button>
      </Form>
    </div>
  );
}

FloorManagementFloorEdit.propTypes = {
  floorId: PropTypes.number.isRequired,
  setIsFormDirty: PropTypes.func,
  setEditFloorFormReference: PropTypes.func,
};

FloorManagementFloorEdit.defaultProps = {
  setIsFormDirty: () => {},
  setEditFloorFormReference: () => {},
};

export default FloorManagementFloorEdit;
