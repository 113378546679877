import { useRef, useCallback, useState } from "react";

import {
  DownloadOutlined,
  PrinterOutlined,
  FrownOutlined,
} from "@ant-design/icons";
import { Col, Row, Form, Tooltip, notification } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useReactToPrint } from "react-to-print";

import csvParser from "../../../helpers/csvParser";
import Chart from "./Chart";
import Filters from "./Filters";
import Table from "./Table";

import "./WCRPrint.css";

function ReportsLayout({
  iconTitle,
  icon,
  fetchData,
  useGraph,
  handleFieldsChange,
  initialFormValues,
  handleOnChangeFilterCallback,
  generateReportForm,
  reportDate,
  propertyId,
  floorId,
  systemId,
  includePreviousYear,
  setReportDate,
  useBasicFiltering,
  showData,
}) {
  const [data, setData] = useState(null);
  const [csv, setCSV] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [graphics, setGraphics] = useState(true);

  const componentToPrintRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  const handleReportGenerate = useCallback(() => {
    fetchData().then((response) => {
      if (response && response.isSuccess) {
        setCSV(response.data.Value);
        setFileName(response.data.FileName);

        // Parsing the raw CSV string to table format
        const parsedData = csvParser(response.data.Value);

        // Extract headers and rows
        const headers = parsedData[0];
        const tableData = parsedData.slice(1).map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header.trim()] = row[index] ? row[index].trim() : "";
          });
          return obj;
        });

        setData(tableData);
      } else {
        notification.info({
          message: (
            <span className="uppercase tracking-widest">Loading data...</span>
          ),
          description: (
            <span className="uppercase tracking-widest">
              {response && response.error && response.error.response
                ? response.error.response.data[0]
                : ""}
            </span>
          ),
          duration: 3,
          placement: "topRight",
        });

        // Clear report data if no records are found from back-end
        setData(null);
        setCSV(null);
        setFileName(null);
      }
    });
  }, [fetchData]);

  const handleExport = useCallback(() => {
    if (data && data.length > 0) {
      const timestamp = dayjs().format("YYYY-MM-DD HH:mm:ss");
      const url = window.URL.createObjectURL(new Blob([csv]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}-${timestamp}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      notification.error({
        message: (
          <span className="uppercase tracking-widest">
            Please generate report.
          </span>
        ),
        description: (
          <span className="uppercase tracking-widest">
            No report data to export. Please generate report first.
          </span>
        ),
        duration: 10,
        placement: "topRight",
        icon: <FrownOutlined className="text-triple-red" />,
      });
    }
  }, [data, csv, fileName]);

  return (
    <Row className="w-full">
      <Col className="w-full xxl:w-[85%] flex xs:flex-col-reverse sm:flex-row">
        <Col ref={componentToPrintRef} className="m-auto p-2 w-full">
          <Row className="w-full xxxl:gap-0 gap-2">
            <Col className="flex w-full gap-2">
              <Col className="flex flex-col xs:w-32 xs:h-32 sm:w-24 sm:h-24 xl:w-20 xl:h-20 text-center items-center rounded-md bg-triple-white p-2 xs:gap-4 sm:gap-2 xl:gap-1">
                <Col className="text-triple-background uppercase xs:text-[10px] sm:text-[8px]">
                  {iconTitle}
                </Col>
                <Col className="xs:w-20 xs:h-20 sm:w-14 sm:h-14 xl:w-12 xl:h-12 border-[5px] border-triple-blue border-solid rounded-full flex items-center justify-center">
                  {icon}
                </Col>
              </Col>
              <Form
                form={generateReportForm}
                name="generate-report-form"
                onFinish={handleReportGenerate}
                onFieldsChange={handleFieldsChange}
                initialValues={initialFormValues.current}
                className="w-full"
              >
                <Filters
                  handleOnChangeFilterCallback={handleOnChangeFilterCallback}
                  generateReportForm={generateReportForm}
                  useBasicFiltering={useBasicFiltering}
                  graphics={graphics}
                  setGraphics={setGraphics}
                />
              </Form>
            </Col>
          </Row>
          {showData && (
            <Row className="pt-2 gap-2 w-full">
              <Col span={24}>
                <Table data={data} />
              </Col>
              {useGraph && graphics && (
                <Col span={24}>
                  <Chart
                    date={reportDate}
                    propertyId={propertyId}
                    floorId={floorId}
                    systemId={systemId}
                    showPreviousWaterConsumptionData={includePreviousYear}
                    setDate={setReportDate}
                  />
                </Col>
              )}
            </Row>
          )}
        </Col>
        <Col className="sm:mt-4 xs:m-auto items-center flex sm:flex-col xs:flex-row gap-3">
          <Tooltip title="Print Report" color="#06a5d3" key="print">
            <PrinterOutlined
              className="text-18 cursor-pointer text-triple-white hover:text-triple-blue"
              onClick={() => handlePrint()}
            />
          </Tooltip>
          <Tooltip title="Download CSV File" color="#06a5d3" key="csv">
            <DownloadOutlined
              className="text-18 cursor-pointer text-triple-white hover:text-triple-blue"
              onClick={handleExport}
            />
          </Tooltip>
        </Col>
      </Col>
    </Row>
  );
}

ReportsLayout.defaultProps = {
  iconTitle: null,
  icon: null,
  fetchData: () => {},
  useGraph: false,
  handleFieldsChange: () => {},
  initialFormValues: {},
  handleOnChangeFilterCallback: () => {},
  generateReportForm: {},
  reportDate: null,
  propertyId: null,
  floorId: null,
  systemId: null,
  includePreviousYear: null,
  setReportDate: () => {},
  useBasicFiltering: false,
  showData: false,
};
ReportsLayout.propTypes = {
  iconTitle: PropTypes.string,
  icon: PropTypes.any,
  fetchData: PropTypes.func,
  useGraph: PropTypes.bool,
  handleFieldsChange: PropTypes.func,
  initialFormValues: PropTypes.object,
  handleOnChangeFilterCallback: PropTypes.func,
  generateReportForm: PropTypes.object,
  reportDate: PropTypes.any,
  propertyId: PropTypes.any,
  floorId: PropTypes.any,
  systemId: PropTypes.any,
  includePreviousYear: PropTypes.bool,
  setReportDate: PropTypes.func,
  useBasicFiltering: PropTypes.bool,
  showData: PropTypes.bool,
};

export default ReportsLayout;
