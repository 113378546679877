import { useEffect, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import useStatisticsBiennialMonthlyWaterConsumption from "../../../api/hooks/useStatisticsBiennialMonthlyWaterConsumption";
import useStatisticsMonthlyWaterConsumption from "../../../api/hooks/useStatisticsMonthlyWaterConsumption";
import DateConstants from "../../../constants/DateConstants";
import generateSkeletonStatisticsAnnualChartData from "../../../helpers/generateSkeletonStatisticsAnnualChartData";
import generateSkeletonStatisticsMonthlyChartData from "../../../helpers/generateSkeletonStatisticsMonthlyChartData";
import { ConvertWater } from "../../../helpers/waterHelper";
import useProfileSettings from "../../../hooks/useProfileSettings";
import AnnualChart from "./StatisticsAnnualChart";
import ChartTitle from "./StatisticsChartTitle";
import StatisticsMonthlyChart from "./StatisticsMonthlyChart";

function StatisticsCharts({
  waterConsumptionData,
  previousWaterConsumptionData,
  statisticsFilters,
  setDate,
  date,
  showPreviousWaterConsumptionData,
}) {
  const [renderMonthlyChart, setRenderMonthlyChart] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState();
  const [monthlyData, setMonthlyData] = useState();
  const year = waterConsumptionData?.Year;

  const { userSettings } = useProfileSettings();

  const { status: queryStatus, refetch: getMonthlyWaterConsumption } =
    useStatisticsMonthlyWaterConsumption(
      year,
      selectedMonth?.MonthAsInt,
      {
        propertyId: statisticsFilters.propertyId,
        floorId: statisticsFilters.floorId,
        systemId: statisticsFilters.systemId,
      },
      { enabled: false, cacheTime: 0 },
    );

  const { refetch: getBiennialMonthlyWaterConsumptionData } =
    useStatisticsBiennialMonthlyWaterConsumption(
      year,
      selectedMonth?.MonthAsInt,
      {
        propertyId: statisticsFilters.propertyId,
        floorId: statisticsFilters.floorId,
        systemId: statisticsFilters.systemId,
      },
      { enabled: false, cacheTime: 0 },
    );

  const handleHideMonthlyChart = () => {
    setRenderMonthlyChart(false);
    setSelectedMonth(null);
  };

  useEffect(() => {
    // Hide the monthly chart if the year has been changed.
    setRenderMonthlyChart(false);
    setSelectedMonth(null);
  }, [date.year]);

  useEffect(() => {
    // Enforce rendering of a specific month chart when a month is selected from the calendar
    const month = waterConsumptionData?.StatisticsData?.find(
      (item) => item.Month === DateConstants.monthsAbbreviated[date.m],
    );
    setSelectedMonth(month);
    // Only when the date.m is changed, we do not want this useEffect when the array of data is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.m]);

  useEffect(() => {
    if (selectedMonth && showPreviousWaterConsumptionData) {
      try {
        getBiennialMonthlyWaterConsumptionData(
          year,
          selectedMonth?.MonthAsInt,
          {
            propertyId: statisticsFilters.propertyId,
            floorId: statisticsFilters.floorId,
            systemId: statisticsFilters.systemId,
          },
        ).then((response) => {
          if (response.status === "error") {
            setMonthlyData(null);
            notification.error({
              message: (
                <span className="uppercase tracking-widest">
                  Something went wrong
                </span>
              ),
              placement: "topRight",
              icon: <FrownOutlined className="text-triple-red" />,
            });
          }
          if (response.status === "success") {
            const { waterUnit } = userSettings;
            const mappedData = response.data.map((monthDetails) => {
              return {
                Year: monthDetails.Year,
                Month: monthDetails.Month,
                TotalWaterConsumption: ConvertWater(
                  waterUnit,
                  monthDetails.TotalWaterConsumption,
                ).toFixed(2),
                StatisticsData: monthDetails.StatisticsData.map((v) => ({
                  Day: dayjs(v.Date).date(),
                  WaterConsumption: ConvertWater(
                    waterUnit,
                    v.WaterConsumption,
                  ).toFixed(2),
                })),
              };
            });
            setMonthlyData(mappedData);
            setRenderMonthlyChart(true);
          }
        });
      } catch (error) {
        notification.error({
          message: (
            <span className="uppercase tracking-widest">{error.message}</span>
          ),
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      }
      setMonthlyData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showPreviousWaterConsumptionData,
    selectedMonth,
    getMonthlyWaterConsumption,
    userSettings.waterUnit,
  ]);

  useEffect(() => {
    if (selectedMonth && !showPreviousWaterConsumptionData) {
      try {
        getMonthlyWaterConsumption(year, selectedMonth?.MonthAsInt, {
          propertyId: statisticsFilters.propertyId,
          floorId: statisticsFilters.floorId,
          systemId: statisticsFilters.systemId,
        }).then((response) => {
          if (response.status === "error") {
            setMonthlyData(null);
            notification.error({
              message: (
                <span className="uppercase tracking-widest">
                  Something went wrong
                </span>
              ),
              placement: "topRight",
              icon: <FrownOutlined className="text-triple-red" />,
            });
          }

          if (response.status === "success") {
            const { waterUnit } = userSettings;
            const mappedData = [
              {
                Year: response.data.Year,
                Month: response.data.Month,
                TotalWaterConsumption: ConvertWater(
                  waterUnit,
                  response.data.TotalWaterConsumption,
                ).toFixed(2),
                StatisticsData: response.data.StatisticsData.map((v) => ({
                  Day: dayjs(v.Date).date(),
                  WaterConsumption: ConvertWater(
                    waterUnit,
                    v.WaterConsumption,
                  ).toFixed(2),
                })),
              },
            ];

            setMonthlyData(mappedData);
            setRenderMonthlyChart(true);
          }
        });
      } catch (ex) {
        notification.error({
          message: (
            <span className="uppercase tracking-widest">{ex.message}</span>
          ),
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      }
      setMonthlyData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, getMonthlyWaterConsumption, userSettings.waterUnit]);

  const titleYears = [
    showPreviousWaterConsumptionData ? previousWaterConsumptionData.Year : "",
    waterConsumptionData.Year,
  ]
    .filter((year) => year !== "")
    .join(" / ");

  const titleMonths = `${
    selectedMonth && selectedMonth.MonthAsInt ? `${selectedMonth.Month} - ` : ""
  }${titleYears}`;

  return (
    <div className="w-full statistics-charts" style={{ height: 288 }}>
      {titleYears && <ChartTitle title={titleYears} />}
      <AnnualChart
        waterConsumptionData={
          waterConsumptionData?.StatisticsData?.length === 0
            ? generateSkeletonStatisticsAnnualChartData(
                waterConsumptionData.Year,
              )
            : waterConsumptionData
        }
        previousWaterConsumptionData={
          previousWaterConsumptionData?.StatisticsData?.length === 0
            ? generateSkeletonStatisticsAnnualChartData(
                previousWaterConsumptionData.Year,
              )
            : previousWaterConsumptionData
        }
        setSelectedMonth={setSelectedMonth}
        setDate={setDate}
        date={date}
        showPreviousWaterConsumptionData={showPreviousWaterConsumptionData}
      />
      {queryStatus === "success" &&
        renderMonthlyChart === true &&
        selectedMonth &&
        monthlyData && (
          <div
            className="w-full statistics-charts-monthly"
            style={{ height: 288 }}
          >
            <Button
              type="primary"
              size="small"
              className="mt-1"
              onClick={handleHideMonthlyChart}
            >
              close monthly chart
            </Button>
            <ChartTitle title={titleMonths} />
            <StatisticsMonthlyChart
              waterConsumptionData={
                monthlyData[0]?.StatisticsData?.length === 0
                  ? generateSkeletonStatisticsMonthlyChartData(
                      monthlyData[0].Year,
                      monthlyData[0].Month,
                    )
                  : monthlyData[0]
              }
              previousWaterConsumptionData={
                monthlyData[1]?.StatisticsData?.length === 0
                  ? generateSkeletonStatisticsMonthlyChartData(
                      monthlyData[1].Year,
                      monthlyData[1].Month,
                    )
                  : monthlyData[1]
              }
              handleHideMonthlyChart={handleHideMonthlyChart}
              showPreviousWaterConsumptionData={
                showPreviousWaterConsumptionData
              }
              setDate={setDate}
              date={date}
            />
          </div>
        )}
    </div>
  );
}

StatisticsCharts.defaultProps = {
  waterConsumptionData: {},
  previousWaterConsumptionData: {},
  statisticsFilters: {},
  showPreviousWaterConsumptionData: false,
  setDate: () => {},
  date: {},
};

StatisticsCharts.propTypes = {
  waterConsumptionData: PropTypes.object,
  previousWaterConsumptionData: PropTypes.object,
  statisticsFilters: PropTypes.object,
  showPreviousWaterConsumptionData: PropTypes.bool,
  setDate: PropTypes.func,
  date: PropTypes.object,
};

export default StatisticsCharts;
