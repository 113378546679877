import { useState, useEffect } from "react";

import { LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Form, Button, Divider, Tooltip, Row, Col, Radio } from "antd";
import { useNavigate } from "react-router-dom";

import useCreateFlowConfiguration from "../../../api/hooks/useCreateFlowConfiguration";
import useFlowRateActions from "../../../api/hooks/useFlowRateActions";
import useFlowRateRange from "../../../api/hooks/useFlowRateRange";
import { useAvailableDeviceSerialNumberOptionsContext } from "../../../context/FdsFiltersValuesProvider";
import useWindowSize from "../../../hooks/useWindowSize";
import DurationTimePicker from "../DurationTimePicker/DurationTimePicker";
import FdsSchedulesListFilters from "../Filters/FdsSchedulesListFilters";
import "./CreateFlowConfiguration.scss";

function CreateFlowConfiguration() {
  const DurationTimePickerId = {
    Low: "LOW",
    Medium: "MEDIUM",
    High: "HIGH",
  };
  const window = useWindowSize();
  const navigate = useNavigate();
  const { data: flowRateRange } = useFlowRateRange();
  const [disabledCreate, setDisabledCreate] = useState(true);

  const [rangeValidLow, setRangeValidLow] = useState(true);
  const [rangeValidMedium, setRangeValidMedium] = useState(true);
  const [rangeValidHigh, setRangeValidHigh] = useState(true);

  const tooltipText =
    "Define the flow rates and following action for the selected devices";
  const [optionsFlowActions, setOptionsFlowActions] = useState([]);
  const flowRateActions = useFlowRateActions();
  const labelActionWhenDetectedDefault = useState("Action When Detected");
  const [labelActionWhenDetected, setLabelActionWhenDetected] = useState(
    labelActionWhenDetectedDefault,
  );
  const [isFlowRateActionsDisabled, setIsFlowRateActionsDisabled] =
    useState(true);
  const [action, setAction] = useState("0");
  const [actionOnLowFlowRate, setActionOnLowFlowRate] = useState("0");
  const [actionOnMedFlowRate, setActionOnMedFlowRate] = useState("0");
  const [actionOnHighFlowRate, setActionOnHighFlowRate] = useState("0");
  const [selectedFlowRateType, setSelectedFlowRateType] = useState("");
  const [lowFlowRateDuration, setLowFlowRateDuration] = useState("00:00");
  const [medFlowRateDuration, setMedFlowRateDuration] = useState("00:00");
  const [highFlowRateDuration, setHighFlowRateDuration] = useState("00:00");
  const [isFlowRateInputClickCaptured, setIsFlowRateInputClickCaptured] =
    useState(false);

  const {
    availableDeviceSerialNumberOptions,
    availableServiceUniqueIdOptions,
  } = useAvailableDeviceSerialNumberOptionsContext();
  const { mutate: createFlowConfiguration, isLoading: isCreating } =
    useCreateFlowConfiguration();

  useEffect(() => {
    const transformedArray = Array.isArray(flowRateActions.data)
      ? flowRateActions.data.map((item) => ({
          label: item.Name,
          value: item.Value.toString(),
        }))
      : [];
    setOptionsFlowActions(transformedArray);
  }, [flowRateActions.data]);

  const timeStringToMinutes = (strValue) => {
    let result = 0;
    const indexOfColon = strValue.indexOf(":");
    if (indexOfColon === -1) return result;
    const parts = strValue.split(":");
    if (parts.length !== 2) return result;
    result = parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
    return result;
  };

  useEffect(() => {
    if (flowRateRange) {
      const lowDurationInMinutes = timeStringToMinutes(lowFlowRateDuration);
      const medDurationInMinutes = timeStringToMinutes(medFlowRateDuration);
      const highDurationInMinutes = timeStringToMinutes(highFlowRateDuration);

      const isLowValid =
        lowDurationInMinutes >= flowRateRange.FromMinutes &&
        lowDurationInMinutes <= flowRateRange.ToMinutes;

      const isMedValid =
        medDurationInMinutes >= flowRateRange.FromMinutes &&
        medDurationInMinutes <= flowRateRange.ToMinutes;

      const isHighValid =
        highDurationInMinutes >= flowRateRange.FromMinutes &&
        highDurationInMinutes <= flowRateRange.ToMinutes;

      setRangeValidLow(isLowValid);
      setRangeValidMedium(isMedValid);
      setRangeValidHigh(isHighValid);

      if (isLowValid && isMedValid && isHighValid) {
        setDisabledCreate(false);
      } else if (!isLowValid || !isMedValid || !isHighValid) {
        setDisabledCreate(true);
      }
    }
  }, [
    availableDeviceSerialNumberOptions,
    lowFlowRateDuration,
    medFlowRateDuration,
    highFlowRateDuration,
    flowRateRange,
  ]);

  const handleFlowRateSave = () => {
    const systemUniqueIds = [];
    availableServiceUniqueIdOptions.forEach((option) => {
      if (option.value === "all") return;
      systemUniqueIds.push(option.value);
    });

    const flowConfigs = [];
    availableDeviceSerialNumberOptions.forEach((o) => {
      const option = JSON.parse(o);

      if (option.value === "all") return;

      const flowConfig = {
        DeviceSerialNumber: option.value,
        LowDuration: lowFlowRateDuration,
        LowAction: actionOnLowFlowRate,
        MediumDuration: medFlowRateDuration,
        MediumAction: actionOnMedFlowRate,
        HighDuration: highFlowRateDuration,
        HighAction: actionOnHighFlowRate,
      };
      flowConfigs.push(flowConfig);
    });

    if (flowConfigs && systemUniqueIds) {
      const model = {
        UpdateFlowConfigurations: flowConfigs,
        SelectedSystemUniqueIds: systemUniqueIds,
      };
      createFlowConfiguration(model);
    }

    navigate("/settings/flow-configuration");
  };

  const onActionChange = ({ target: { value } }) => {
    switch (selectedFlowRateType) {
      case DurationTimePickerId.Low:
        setActionOnLowFlowRate(value);
        break;
      case DurationTimePickerId.Medium:
        setActionOnMedFlowRate(value);
        break;
      case DurationTimePickerId.High:
        setActionOnHighFlowRate(value);
        break;
      default:
        break;
    }

    setAction(value);
  };

  const handleLowFlowRateOnClick = (id) => {
    setAction(actionOnLowFlowRate);
    setLabelActionWhenDetected("Action When Detected Low Flow Rate");
    setIsFlowRateActionsDisabled(false);
    setIsFlowRateInputClickCaptured(true);
    setSelectedFlowRateType(id);
  };

  const handleMediumFlowRateOnClick = (id) => {
    setAction(actionOnMedFlowRate);
    setLabelActionWhenDetected("Action When Detected Medium Flow Rate");
    setIsFlowRateActionsDisabled(false);
    setIsFlowRateInputClickCaptured(true);
    setSelectedFlowRateType(id);
  };

  const handleHighFlowRateOnClick = (id) => {
    setAction(actionOnHighFlowRate);
    setLabelActionWhenDetected("Action When Detected High Flow Rate");
    setIsFlowRateActionsDisabled(false);
    setIsFlowRateInputClickCaptured(true);
    setSelectedFlowRateType(id);
  };

  const handleLowFlowRateOnChange = (newValue) => {
    setLowFlowRateDuration(newValue);
  };

  const handleMediumFlowRateOnChange = (newValue) => {
    setMedFlowRateDuration(newValue);
  };

  const handleHighFlowRateOnChange = (newValue) => {
    setHighFlowRateDuration(newValue);
  };

  const handleNoControlClick = () => {
    if (isFlowRateInputClickCaptured) {
      setLabelActionWhenDetected(labelActionWhenDetectedDefault);
      setIsFlowRateActionsDisabled(true);
      setIsFlowRateInputClickCaptured(false);
    }
  };

  return (
    <Form
      disabled={isCreating}
      className="create-new-schedule h-full flex flex-col"
      role="presentation"
      onClick={handleNoControlClick}
    >
      <Button
        className="mb-5 self-start uppercase tracking-widest"
        type="link"
        onClick={() => {
          navigate(-1);
        }}
      >
        <LeftOutlined />
        Back
      </Button>

      <div className="schedule-header mb-8">
        {window?.width > 992 && (
          <div className="flex items-center">
            <h1 className="page-title mb-0">Create New Flow Configuration</h1>
            <Tooltip
              placement="top"
              title={tooltipText}
              className="ml-4 text-triple-white"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        )}

        <Button
          size="large"
          type="primary"
          htmlType="submit"
          className="ml-auto"
          disabled={disabledCreate}
          onClick={handleFlowRateSave}
        >
          Create Flow Configuration
        </Button>
      </div>
      <div className="mb-8">
        <div
          role="presentation"
          onKeyDown={() => {}}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Form.Item>
            <FdsSchedulesListFilters />
          </Form.Item>
        </div>
        <Divider orientation="left" orientationMargin={0} />
      </div>
      <div className="mb-8">
        {window?.width > 992 && (
          <div className="flex items-center">
            <h2 className="section-title mb-8">Flow Rate</h2>
          </div>
        )}
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <div className="flex items-center">
              <span className="control-title mb-2">
                Low
                <Tooltip
                  placement="top"
                  title="The duration of low flow rate in hours and minutes (hh:mm). Value is in range 00:01 - 99:00"
                  className="ml-4 text-triple-white"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            </div>
            <div
              role="presentation"
              onKeyDown={() => {}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Form.Item>
                <DurationTimePicker
                  id={DurationTimePickerId.Low}
                  handleOnClickCallback={handleLowFlowRateOnClick}
                  handleOnChangeCallback={handleLowFlowRateOnChange}
                  rangeValid={rangeValidLow}
                />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <div className="flex items-center">
              <span className="control-title mb-2">
                Medium
                <Tooltip
                  placement="top"
                  title="The duration of medium flow rate in hours and minutes (hh:mm). Value is in range 00:01 - 99:00"
                  className="ml-4 text-triple-white"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            </div>
            <div
              role="presentation"
              onKeyDown={() => {}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Form.Item>
                <DurationTimePicker
                  id={DurationTimePickerId.Medium}
                  handleOnClickCallback={handleMediumFlowRateOnClick}
                  handleOnChangeCallback={handleMediumFlowRateOnChange}
                  rangeValid={rangeValidMedium}
                />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <div className="flex items-center">
              <span className="control-title mb-2">
                High
                <Tooltip
                  placement="top"
                  title="The duration of high flow rate in hours and minutes (hh:mm). Value is in range 00:01 - 99:00"
                  className="ml-4 text-triple-white"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            </div>
            <div
              role="presentation"
              onKeyDown={() => {}}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Form.Item>
                <DurationTimePicker
                  id={DurationTimePickerId.High}
                  handleOnClickCallback={handleHighFlowRateOnClick}
                  handleOnChangeCallback={handleHighFlowRateOnChange}
                  rangeValid={rangeValidHigh}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider orientation="left" orientationMargin={0} />
      </div>
      <div className="mb-8">
        {window?.width > 992 && (
          <div className="flex items-center">
            <h2 className="section-title mb-8">{labelActionWhenDetected}</h2>
          </div>
        )}
        <div
          className="flex items-center radio-group-container"
          role="presentation"
          onKeyDown={() => {}}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Form.Item>
            <Radio.Group
              options={optionsFlowActions}
              onChange={onActionChange}
              value={action}
              optionType="button"
              buttonStyle="solid"
              defaultValue="0"
              size="medium"
              disabled={isFlowRateActionsDisabled}
            />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

export default CreateFlowConfiguration;
