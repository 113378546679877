import { useEffect, useState } from "react";

import { Checkbox, Table, Transfer } from "antd";
import PropTypes from "prop-types";

import useGetSystemsForFloorManagement from "../../../../../api/hooks/useGetSystemsForFloorManagement";
import "../scss/FloorManagementFloorSystems.scss";

function FloorManagementFloorSystems({
  editFloorFormReference,
  floorId,
  setDisableSaveChanges,
  setIsFormDirty,
}) {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [leftTableData, setLeftTableData] = useState([]);
  const [rightTableData, setRightTableData] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const { data: systems } = useGetSystemsForFloorManagement(floorId, {
    enabled: true,
  });

  // Columns for the table
  const columns = [
    {
      title: "System",
      render: (text, record) => record?.Name,
    },
    {
      title: "Hub Name",
      render: (text, record) => record?.Hub?.Name || "N/A",
    },
    {
      title: "Serial Number",
      render: (text, record) => record?.Hub?.SerialNumber,
    },
    {
      title: "Location",
      render: (text, record) => record?.Hub?.Location || "N/A",
    },
  ];

  useEffect(() => {
    if (systems) {
      const allSystems = [
        ...systems.AssignedSystems,
        ...systems.UnassignedSystems,
      ].map((system) => {
        return {
          ...system,
          key: system.Id,
        };
      });

      const unassignedKeys = systems.UnassignedSystems?.map((x) => x.Id);
      setDataSource(allSystems);
      setTargetKeys(unassignedKeys);
      const assignedSystems = allSystems.filter(
        (system) => !unassignedKeys.includes(system.key),
      );
      const unassignedSystems = allSystems.filter((system) =>
        unassignedKeys.includes(system.key),
      );

      setLeftTableData(assignedSystems);
      setRightTableData(unassignedSystems);
    }
  }, [systems]);

  // Handling the Transfer of Items
  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
    const selectedSystemsForAssignment = dataSource.filter(
      (system) => !nextTargetKeys.includes(system.key),
    );
    const selectedSystemsForUnassignment = dataSource.filter((system) =>
      nextTargetKeys.includes(system.key),
    );
    setLeftTableData(selectedSystemsForAssignment);
    setRightTableData(selectedSystemsForUnassignment);
    editFloorFormReference.setFieldsValue({
      Systems: {
        AssignedSystems: selectedSystemsForAssignment,
        UnassignedSystems: selectedSystemsForUnassignment,
      },
    });
    setDisableSaveChanges(false);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    setIsFormDirty(true);
  };

  return (
    <div className="floor-system-table-transfer">
      <Transfer
        dataSource={dataSource}
        titles={["Assigned Systems", "Unassigned Systems"]}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        showSelectAll
        operations={["Unassign", "Assign to floor"]}
      >
        {({
          direction,
          filteredItems,
          onItemSelectAll,
          onItemSelect,
          selectedKeys: listSelectedKeys,
        }) => {
          const columnsWithSelect = [
            {
              title: (
                <div>
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        onItemSelectAll(
                          filteredItems.map((item) => item.key),
                          true,
                        );
                      } else {
                        onItemSelectAll(
                          filteredItems.map((item) => item.key),
                          false,
                        );
                      }
                    }}
                    checked={
                      listSelectedKeys.length === filteredItems.length &&
                      filteredItems.length > 0
                    }
                  />
                </div>
              ),
              dataIndex: "system",
              key: "system",
              render: (text, record) => (
                <Checkbox
                  checked={listSelectedKeys.includes(record.key)}
                  onChange={() =>
                    onItemSelect(
                      record.key,
                      !listSelectedKeys.includes(record.key),
                    )
                  }
                />
              ),
            },
            ...columns,
          ];

          return (
            <Table
              rowKey="key"
              columns={columnsWithSelect}
              dataSource={direction === "left" ? leftTableData : rightTableData}
              pagination
              size="small"
            />
          );
        }}
      </Transfer>
    </div>
  );
}

FloorManagementFloorSystems.propTypes = {
  editFloorFormReference: PropTypes.object,
  floorId: PropTypes.number.isRequired,
  setDisableSaveChanges: PropTypes.func,
  setIsFormDirty: PropTypes.func,
};

FloorManagementFloorSystems.defaultProps = {
  editFloorFormReference: {},
  setDisableSaveChanges: () => {},
  setIsFormDirty: () => {},
};

export default FloorManagementFloorSystems;
