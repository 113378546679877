// import { Badge } from "antd";
import PropTypes from "prop-types";
import "./StatisticsChartTitle.scss";

function ChartTitle({ title }) {
  return (
    <div className="bar-chart-title bg-triple-white rounded-t-xl flex p-1 mt-1">
      {/* <span className="text-triple-header self-end w-1/2">
        Consumption
        <Badge
          color="#e32f2f"
          dot
          text="Leak"
          size="small"
          style={{ color: "#363346" }}
          className="ml-5"
        />
        <Badge
          color="#abb0ba"
          dot
          text="Offline"
          size="small"
          style={{ color: "#363346" }}
          className="ml-5"
        />
        <Badge
          color="#f2a832"
          dot
          text="Check"
          size="small"
          style={{ color: "#363346" }}
          className="ml-5"
        />
        <Badge
          status="success"
          dot
          text="Ok"
          size="small"
          style={{ color: "#363346" }}
          className="ml-5"
        />
      </span> */}
      <span className="rounded-md px-4 py-1 bg-triple-blue">{title}</span>
    </div>
  );
}
ChartTitle.defaultProps = {
  title: "",
};

ChartTitle.propTypes = {
  title: PropTypes.string,
};
export default ChartTitle;
