function EventsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)" stroke="#E1E0E3">
        <path
          d="M9 4H7c-.53 0-1.04.224-1.414.622A2.194 2.194 0 0 0 5 6.125v12.75c0 .564.21 1.104.586 1.503C5.96 20.776 6.47 21 7 21h10c.53 0 1.04-.224 1.414-.622.375-.399.586-.94.586-1.503V6.125c0-.564-.21-1.104-.586-1.503A1.942 1.942 0 0 0 17 4h-2M9 13h.01M13 13h2M9 10h.01M13 10h2M9 16h.01M13 16h2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="9.5" y="3.5" width="5" height="2" rx=".7" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EventsIcon;
