import dayjs from "dayjs";

/* eslint-disable import/prefer-default-export */
export function ConvertDurationInMinutesToHours(durationInMinutes) {
  const hour = Math.floor(durationInMinutes / 60);
  const minute = durationInMinutes % 60;
  const formattedHours = dayjs()
    .set("hour", hour)
    .set("minute", minute)
    .format("HH:mm");
  return formattedHours;
}

export const ConvertTimeToDurationInMinutes = (time) => {
  const duration = time.hour() * 60 + time.minute();
  return duration;
};
