import { Row, Button } from "antd";
import PropTypes from "prop-types";

import useProfileSettings from "../../../hooks/useProfileSettings";
import "./StatisticsConsumptionCards.scss";

function StatisticsConsumptionCards({
  waterConsumptionTotal,
  showPreviousWaterConsumptionData,
  onToggle,
}) {
  const { userSettings } = useProfileSettings();
  return (
    <Row>
      <div className="chart-type">
        <Button
          className="stats-button mt-1 mx-1"
          size="small"
          type="primary"
          onClick={onToggle}
        >
          {showPreviousWaterConsumptionData
            ? "Hide Last Year"
            : "Show Last Year"}
        </Button>
      </div>
      <div className="data-cards">
        <div className="water-consumption">
          <div className="w-consumption">
            <span className="text-triple-header mr-2">
              {waterConsumptionTotal.toFixed(2)}
            </span>
            {userSettings?.waterUnit === "Litres" && (
              <span className="text-triple-header">
                m<sup>3</sup>
              </span>
            )}
            {userSettings?.waterUnit === "Gallons" && (
              <span className="text-triple-header">gal</span>
            )}
          </div>
          <div className="mt-2">
            <span>Consumption</span>
          </div>
        </div>
      </div>
    </Row>
  );
}

StatisticsConsumptionCards.defaultProps = {
  waterConsumptionTotal: 0,
  showPreviousWaterConsumptionData: false,
  onToggle: () => {},
};

StatisticsConsumptionCards.propTypes = {
  waterConsumptionTotal: PropTypes.number,
  showPreviousWaterConsumptionData: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default StatisticsConsumptionCards;
