import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useGetSystemsForFloorManagement(
  floorId,
  options,
  key = "getSystemsForFloorManagement",
) {
  const axiosPrivate = useAxiosPrivate();

  const getSystemsForFloorManagement = async () => {
    const systemsToFloor = await axiosPrivate({
      method: "GET",
      url: `Systems/GetForFloorManagement/${floorId}`,
    }).then((res) => res.data);
    return systemsToFloor;
  };

  return useQuery([key, floorId], () => getSystemsForFloorManagement(), {
    ...options,
  });
}

export default useGetSystemsForFloorManagement;
