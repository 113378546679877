import { useState, useEffect } from "react";

import { Col, Row } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import useStatisticsAnnualWaterConsumption from "../../../api/hooks/useStatisticsAnnualWaterConsumption";
import useStatisticsBiennialWaterConsumption from "../../../api/hooks/useStatisticsBiennialWaterConsumption";
import { ConvertWater } from "../../../helpers/waterHelper";
import useProfileSettings from "../../../hooks/useProfileSettings";
import StatisticsChart from "../../Statistics/Charts/StatisticsCharts";

const propTypes = {
  date: PropTypes.any,
  propertyId: PropTypes.string,
  floorId: PropTypes.string,
  systemId: PropTypes.string,
  showPreviousWaterConsumptionData: PropTypes.bool,
  setDate: PropTypes.func,
};

const defaultProps = {
  date: {},
  propertyId: "",
  floorId: "",
  systemId: "",
  showPreviousWaterConsumptionData: false,
  setDate: () => {},
};

function CustomChart({
  date,
  propertyId,
  floorId,
  systemId,
  showPreviousWaterConsumptionData,
  setDate,
}) {
  const [waterConsumptionData, setWaterConsumptionData] = useState();

  // Fetching water consumption data
  const annualWaterConsumption = useStatisticsAnnualWaterConsumption(
    date && date.year ? date.year : new Date().getFullYear(),
    {
      propertyId,
      floorId,
      systemId,
    },
    { enabled: false, cacheTime: 0 },
  );

  const biennialWaterConsumption = useStatisticsBiennialWaterConsumption(
    date && date.year ? date.year : new Date().getFullYear(),
    {
      propertyId,
      floorId,
      systemId,
    },
    { enabled: false, cacheTime: 0 },
  );

  const { userSettings } = useProfileSettings();

  useEffect(() => {
    if (date) {
      const fetchData = showPreviousWaterConsumptionData
        ? biennialWaterConsumption.refetch
        : annualWaterConsumption.refetch;

      fetchData(date.year - 1, {
        propertyId,
        floorId,
        systemId,
      }).then((response) => {
        const { waterUnit } = userSettings;

        let responseDataArray = [];
        if (response && response.data && Array.isArray(response.data)) {
          responseDataArray = response.data;
        } else {
          const emptyPreviousYearData = {
            Year: response.data.Year - 1,
            TotalWaterConsumption: 0,
            StatisticsData: [],
          };
          const thisYearData = response.data;
          responseDataArray = [emptyPreviousYearData, thisYearData];
        }

        const mappedData = responseDataArray.map((yearDetails) => ({
          Year: yearDetails.Year,
          TotalWaterConsumption: ConvertWater(
            waterUnit,
            yearDetails.TotalWaterConsumption,
          ),
          StatisticsData: yearDetails.StatisticsData.map((s) => ({
            Month: s.Month,
            MonthAsInt: s.MonthAsInt,
            WaterConsumption: ConvertWater(
              waterUnit,
              s.WaterConsumption,
            ).toFixed(2),
          })),
        }));

        setWaterConsumptionData(mappedData);
      });
    }
  }, [
    propertyId,
    floorId,
    systemId,
    date,
    annualWaterConsumption.refetch,
    biennialWaterConsumption.refetch,
    userSettings,
    showPreviousWaterConsumptionData,
  ]);

  if (date === null || date === "") {
    return (
      <Row>
        <Col span={24}>
          <StatisticsChart
            statisticsFilters={{
              propertyId,
              floorId,
              systemId,
            }}
            waterConsumptionData={
              waterConsumptionData && waterConsumptionData[1]
                ? waterConsumptionData[1]
                : {}
            }
            previousWaterConsumptionData={
              waterConsumptionData && waterConsumptionData[0]
                ? waterConsumptionData[0]
                : {}
            }
            showPreviousWaterConsumptionData={showPreviousWaterConsumptionData}
            setDate={setDate}
            date={dayjs()}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col span={24}>
        {(biennialWaterConsumption.status === "success" ||
          annualWaterConsumption.status === "success") && (
          <StatisticsChart
            statisticsFilters={{
              propertyId,
              floorId,
              systemId,
            }}
            waterConsumptionData={
              waterConsumptionData && waterConsumptionData[1]
                ? waterConsumptionData[1]
                : {}
            }
            previousWaterConsumptionData={
              waterConsumptionData && waterConsumptionData[0]
                ? waterConsumptionData[0]
                : {}
            }
            showPreviousWaterConsumptionData={showPreviousWaterConsumptionData}
            setDate={setDate}
            date={dayjs(date)}
          />
        )}
      </Col>
    </Row>
  );
}

CustomChart.propTypes = propTypes;
CustomChart.defaultProps = defaultProps;

export default CustomChart;
