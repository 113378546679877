import { Row, Col, Divider } from "antd";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import "./Property.scss";

import useWindowSize from "../../../hooks/useWindowSize";
import PropertyWaterConsumptionChart from "../PropertyCharts/PropertyWaterConsumptionChart";
import PropertyWaterSavingsChart from "../PropertyCharts/PropertyWaterSavingsChart";
import PropertyDetails from "./PropertyDetails";
import PropertyImage from "./PropertyImage";

const propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line
  property: PropTypes.object,
};

const defaultProps = { className: "", property: {} };

function PropertyCard({ className, property }) {
  const navigate = useNavigate();
  const window = useWindowSize();

  return (
    <div
      role="none"
      className={`property sm:my-2 my-4 ${className} ${
        property?.AlertsCount > 0 ? "border-triple-red" : "border-triple-blue"
      } `}
      onClick={() => {
        navigate(`property/${property?.Id}`);
      }}
    >
      <Row className="h-full">
        {/* PROPERTY IMG */}
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <PropertyImage imageUrl={property?.ImageUrl} />
        </Col>

        {/* PROPERTY DETAILS */}
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <PropertyDetails property={property} />
        </Col>

        {/* DIVIDER */}
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 1 }}
          className="flex items-center justify-center lg:m-0"
        >
          <Divider
            type={window?.width > 992 ? "vertical" : "horizontal"}
            className="lg:h-4/5 m-0"
          />
        </Col>

        {/* CHARTS */}
        <Col xs={{ span: 24 }} lg={{ span: 11 }}>
          <div className="property-charts">
            <PropertyWaterConsumptionChart
              className="justify-between"
              data={property?.TotalWaterConsumption}
            />
            {window?.width < 768 && window?.width > 576 && (
              <Divider type="horizontal" className="my-4 lg:h-4/5 lg:my-0" />
            )}
            <PropertyWaterSavingsChart
              className="justify-between"
              percent={property?.TotalWaterConsumption?.WaterSavingsPercentage}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

PropertyCard.propTypes = propTypes;
PropertyCard.defaultProps = defaultProps;

export default PropertyCard;
