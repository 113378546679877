import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useProperties(params, options, key = "getProperties") {
  const axiosPrivate = useAxiosPrivate();

  const getProperties = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: "/Property/Overview",
      params,
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key, params], () => getProperties(params), {
    ...options,
  });
}

export default useProperties;
