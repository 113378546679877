function ScheduleIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="surface1">
        <path
          className="blue-fill-color"
          stroke=" none"
          fillRule=" evenodd"
          fill=" none"
          d="M 4.968 11.97 C 7.048 11.966 9.124 11.97 11.2 11.977 C 11.483 12.505 11.855 12.969 12.309 13.361 C 12.313 13.369 12.321 13.373 12.332 13.373 C 12.462 13.491 12.599 13.598 12.744 13.693 C 12.747 13.704 12.756 13.708 12.768 13.708 C 12.768 13.72 12.776 13.724 12.787 13.724 C 12.791 13.732 12.799 13.739 12.811 13.735 C 12.811 13.747 12.819 13.751 12.83 13.751 C 12.834 13.763 12.842 13.767 12.854 13.767 C 13.861 14.411 14.95 14.624 16.129 14.4 C 17.202 14.196 18.107 13.696 18.855 12.909 C 18.945 12.808 19.036 12.701 19.118 12.591 C 19.126 14.427 19.13 16.264 19.126 18.1 C 14.409 18.065 9.689 18.03 4.968 17.998 L 4.968 11.97 Z M 4.968 11.97"
        />
        <path
          className="blue-fill-color"
          stroke=" none"
          fillRule=" evenodd"
          fill=" none"
          d="M 4.971 8.709 C 6.881 8.706 8.791 8.709 10.702 8.713 C 10.564 9.371 10.568 10.024 10.714 10.682 C 10.741 10.804 10.772 10.926 10.803 11.044 L 4.971 11.044 L 4.971 8.709 Z M 4.971 8.709"
        />
        <path
          className="blue-fill-color"
          stroke=" none"
          fillRule=" evenodd"
          fill=" none"
          d="M 17.308 6.523 C 17.308 6.534 17.315 6.538 17.327 6.538 C 17.344 6.554 17.363 6.567 17.387 6.575 C 17.391 6.586 17.398 6.59 17.406 6.59 C 17.418 6.602 17.434 6.614 17.449 6.618 C 17.453 6.63 17.461 6.634 17.474 6.634 C 17.583 6.712 17.689 6.795 17.792 6.882 C 17.796 6.89 17.804 6.895 17.815 6.895 L 17.965 7.04 C 17.969 7.052 17.977 7.056 17.988 7.056 C 18.866 7.958 19.233 9.036 19.095 10.296 C 19.016 10.83 18.839 11.327 18.556 11.781 C 18.544 11.785 18.54 11.793 18.54 11.801 C 18.504 11.856 18.465 11.908 18.43 11.964 C 18.422 11.968 18.414 11.975 18.418 11.983 C 18.319 12.105 18.221 12.224 18.118 12.343 C 18.103 12.35 18.091 12.362 18.083 12.378 C 18.052 12.414 18.021 12.445 17.988 12.481 C 17.961 12.497 17.934 12.52 17.914 12.544 C 17.902 12.552 17.895 12.563 17.887 12.575 C 17.866 12.579 17.854 12.595 17.843 12.611 C 17.016 13.311 16.064 13.634 14.978 13.591 C 14.273 13.528 13.648 13.279 13.092 12.848 C 12.9 12.69 12.719 12.52 12.549 12.343 C 12.549 12.33 12.545 12.322 12.533 12.318 C 12.396 12.161 12.27 11.995 12.155 11.817 C 12.155 11.805 12.151 11.797 12.14 11.797 C 11.62 10.944 11.435 10.024 11.585 9.036 C 11.806 7.989 12.356 7.171 13.246 6.586 C 14.214 5.982 15.257 5.823 16.363 6.113 C 16.698 6.206 17.009 6.345 17.308 6.523 Z M 17.308 6.523"
        />
      </g>
      <g id="surface2">
        <path
          className="purple-fill-color"
          stroke="none"
          fill="#E1E0E3"
          d="M 15.059 5.016 C 14.934 5.023 14.762 5.043 14.652 5.059 C 13.203 5.27 11.926 6.121 11.199 7.367 C 11.125 7.492 11.027 7.684 10.977 7.801 L 11.17 7.738 L 4.004 7.738 L 4.004 18.977 L 4.27 18.98 C 4.418 18.98 7.832 19.008 11.852 19.035 C 15.875 19.063 19.375 19.086 19.625 19.09 L 20.086 19.094 L 20.082 14.309 C 20.082 10.715 20.078 9.496 20.074 9.426 C 20.031 8.98 19.969 8.66 19.848 8.285 C 19.652 7.688 19.348 7.148 18.926 6.656 C 18.82 6.535 18.547 6.262 18.418 6.152 C 17.727 5.563 16.895 5.184 16.012 5.059 C 15.77 5.023 15.676 5.016 15.383 5.016 C 15.227 5.012 15.082 5.012 15.059 5.016 Z M 15.762 6.012 C 16.508 6.102 17.176 6.387 17.762 6.875 C 17.871 6.965 18.129 7.223 18.223 7.336 C 18.516 7.691 18.742 8.09 18.891 8.512 C 19.137 9.211 19.168 9.988 18.984 10.699 C 18.844 11.223 18.594 11.707 18.234 12.137 C 18.141 12.254 17.883 12.512 17.766 12.605 C 17.273 13.016 16.695 13.301 16.078 13.438 C 15.605 13.539 15.137 13.563 14.711 13.496 C 13.648 13.328 12.664 12.621 12.086 11.613 C 11.688 10.918 11.508 10.121 11.57 9.355 C 11.656 8.289 12.234 7.309 13.145 6.672 C 13.344 6.535 13.645 6.371 13.875 6.273 C 14.219 6.133 14.613 6.039 15 6 C 15.168 5.984 15.594 5.992 15.762 6.012 Z M 10.676 8.719 C 10.676 8.723 10.668 8.762 10.66 8.813 C 10.523 9.5 10.559 10.234 10.766 10.973 L 10.781 11.035 L 4.98 11.035 L 4.98 8.719 L 7.828 8.719 C 9.395 8.719 10.676 8.719 10.676 8.719 Z M 11.238 12.098 C 11.871 13.207 12.91 14.031 14.086 14.359 C 14.469 14.465 14.805 14.512 15.215 14.508 C 15.449 14.508 15.566 14.504 15.781 14.477 C 16.813 14.355 17.75 13.934 18.504 13.258 C 18.688 13.098 18.918 12.852 19.059 12.676 L 19.102 12.621 L 19.105 15.359 C 19.105 16.867 19.105 18.102 19.105 18.105 C 19.102 18.109 5.492 18.012 5.199 18.008 L 4.98 18.004 L 4.98 12.016 L 11.191 12.016 L 11.238 12.098 Z M 11.238 12.098"
        />
        <path
          className="purple-fill-color"
          stroke="none"
          fill="#E1E0E3"
          strokeWidth="0"
          d="M 15.144531 6.816406 C 15.042969 6.835938 14.953125 6.882812 14.882812 6.953125 C 14.824219 7.011719 14.789062 7.066406 14.761719 7.144531 L 14.738281 7.203125 L 14.738281 10.328125 L 15.839844 10.699219 C 16.445312 10.90625 16.957031 11.074219 16.976562 11.078125 C 16.992188 11.082031 17.039062 11.085938 17.078125 11.085938 C 17.410156 11.085938 17.644531 10.753906 17.53125 10.4375 C 17.523438 10.410156 17.507812 10.375 17.5 10.359375 C 17.453125 10.28125 17.375 10.207031 17.300781 10.167969 C 17.28125 10.15625 16.917969 10.03125 16.492188 9.890625 L 15.71875 9.628906 L 15.710938 7.222656 L 15.695312 7.171875 C 15.644531 7 15.527344 6.882812 15.359375 6.832031 C 15.300781 6.816406 15.199219 6.808594 15.144531 6.816406 Z M 7.011719 13.773438 L 7.011719 14.542969 L 7.78125 14.539062 L 8.550781 14.539062 L 8.550781 13 L 7.011719 13 Z M 9.886719 13.769531 L 9.886719 14.539062 L 10.65625 14.539062 L 11.425781 14.542969 L 11.425781 13 L 9.882812 13 Z M 7.011719 16.21875 L 7.011719 16.988281 L 8.550781 16.988281 L 8.550781 15.449219 L 7.011719 15.449219 Z M 9.882812 16.21875 L 9.882812 16.988281 L 11.425781 16.988281 L 11.425781 15.449219 L 9.882812 15.449219 Z M 12.714844 16.21875 L 12.714844 16.988281 L 14.246094 16.988281 L 14.246094 15.449219 L 12.714844 15.449219 Z M 15.535156 16.21875 L 15.535156 16.988281 L 17.078125 16.988281 L 17.078125 15.449219 L 15.535156 15.449219 Z M 15.535156 16.21875 "
        />
      </g>
    </svg>
  );
}

export default ScheduleIcon;
