// import { Badge, Col, Divider, Row } from "antd";
import PropTypes from "prop-types";

function StatisticsCustomTooltip({ active, payload, chartType, waterUnit }) {
  let unitOfMeasure;
  switch (waterUnit) {
    case "Gallons":
      unitOfMeasure = "gal";
      break;
    case "Litres":
    default:
      unitOfMeasure = "m3";
      break;
  }

  const textContent = (fill, name, value) => {
    return (
      <p
        className="px-1 my-1"
        style={{ color: `${fill}` }}
      >{`${name} : ${value} ${unitOfMeasure}`}</p>
    );
  };

  // const drawEventBadges = (
  //   alertCount,
  //   offlineCount,
  //   checkCount,
  //   className,
  //   name,
  // ) => {
  //   return (
  //     <>
  //       <p className={`px-0 my-0 text-center ${className}`}>{name}</p>
  //       <p className="pr-1 my-1">
  //         <Badge
  //           count={alertCount ?? 0}
  //           showZero
  //           className="pr-1"
  //           color="#e32f2f"
  //         />
  //         Alert
  //       </p>
  //       <p className="pr-1 my-1">
  //         <Badge
  //           count={offlineCount ?? 0}
  //           showZero
  //           className="pr-1"
  //           color="#abb0ba"
  //         />
  //         Offline
  //       </p>
  //       <p className="pr-1 my-1">
  //         <Badge
  //           count={checkCount ?? 0}
  //           showZero
  //           className="pr-1"
  //           color="#f2a832"
  //         />
  //         Check
  //       </p>
  //     </>
  //   );
  // };

  // const eventsContent = (payload) => {
  //   if (payload.length > 1) {
  //     return (
  //       <>
  //         <Divider dashed />
  //         <div className="flex-row justify-center items-center">
  //           <Row className="flex justify-center items-center">
  //             <Col className="px-1">
  //               {drawEventBadges(
  //                 payload[0].payload.PreviousYearAlerts,
  //                 payload[0].payload.PreviousYearOffline,
  //                 payload[0].payload.PreviousYearChecks,
  //                 null,
  //                 payload[0].name,
  //               )}
  //             </Col>
  //             <Col className="px-1">
  //               {drawEventBadges(
  //                 payload[1].payload.Alerts,
  //                 payload[1].payload.Offline,
  //                 payload[1].payload.Checks,
  //                 null,
  //                 payload[1].name,
  //               )}
  //             </Col>
  //           </Row>
  //         </div>
  //       </>
  //     );
  //   }

  //   return (
  //     <div className="flex-row justify-center items-center">
  //       {drawEventBadges(
  //         payload[0].payload.Alerts,
  //         payload[0].payload.Offline,
  //         payload[0].payload.Checks,
  //         "hidden",
  //         payload[0].name,
  //       )}
  //     </div>
  //   );
  // };

  const tooltipContent = (payload) => {
    if (payload.length > 1) {
      return (
        <>
          <div className="flex-col inline-flex">
            {textContent(payload[0].fill, payload[0].name, payload[0].value)}
            {textContent(payload[1].fill, payload[1].name, payload[1].value)}
          </div>
          {/* {eventsContent(payload)} */}
        </>
      );
    }

    return (
      <>
        <div className="inline-flex">
          {textContent(payload[0].fill, payload[0].name, payload[0].value)}
        </div>
        {/* {eventsContent(payload)} */}
      </>
    );
  };

  const payloadContent = (payload, key) => {
    return (
      <div className="border border-solid rounded bg-triple-white text-triple-background border-sky-500">
        <p className="px-2 my-1 text-center">{key}</p>
        <div className="px-2 my-1">{tooltipContent(payload)}</div>
      </div>
    );
  };

  if (active && payload && payload.length) {
    if (chartType === "Annual") {
      return payloadContent(payload, payload[0].payload.Key);
    }

    if (chartType === "Monthly") {
      return payloadContent(payload, `Day ${payload[0].payload.Key}`);
    }
  }

  return null;
}

StatisticsCustomTooltip.defaultProps = {
  active: false,
  payload: [],
  chartType: PropTypes.string,
  waterUnit: "",
};

StatisticsCustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  chartType: PropTypes.string,
  waterUnit: PropTypes.string,
};
export default StatisticsCustomTooltip;
