function LogoutIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="#E1E0E3">
        <path
          d="M15.016 7.39v-.934a3.685 3.685 0 0 0-3.685-3.685H6.456a3.685 3.685 0 0 0-3.684 3.685v11.13a3.685 3.685 0 0 0 3.684 3.686h4.885a3.675 3.675 0 0 0 3.675-3.674v-.944M21.81 12.021H9.767M18.881 9.106l2.928 2.915-2.928 2.916"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="blue-fill-color purple-stroke-color"
        />
      </g>
    </svg>
  );
}

export default LogoutIcon;
