import { useEffect, useState } from "react";

import { Calendar } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import "./StatisticsCalendar.scss";
// import useStatisticsMonthlyEventsOverview from "../../../api/hooks/useStatisticsMonthlyEventsOverview";
import DateConstants from "../../../constants/DateConstants";

function StatisticsCalendar({
  setDate,
  date /* property, floorId, systemId */,
}) {
  // const { refetch: getMonthlyEventsOverview } =
  //   useStatisticsMonthlyEventsOverview(
  //     date.year,
  //     date.m + 1,
  //     {
  //       propertyId: property?.PropertyId,
  //       floorId,
  //       systemId,
  //     },
  //     {
  //       enabled: true,
  //       cacheTime: 0,
  //     },
  //   );
  const [calendarDate, setCalendarDate] = useState();
  // const [monthlyEvents, setMonthlyEvents] = useState();
  const onPanelChange = (value) => {
    const daysInMonth = new Date(value.$y, value.$M + 1, 0).getDate();

    setDate({
      MM: DateConstants.months[value.$M],
      m: value.$M,
      year: value.$y,
      d: dayjs().$D >= daysInMonth ? daysInMonth : dayjs().$D,
    });
  };

  useEffect(() => {
    setCalendarDate(dayjs(new Date(date?.year, date?.m, date?.d)));
    // getMonthlyEventsOverview().then((response) => {
    //   const mappedData = response.data?.Data.map((e) => ({
    //     Year: response.data.Year,
    //     Month: response.data.Month,
    //     Day: e.Day,
    //     Leaks: e.Leaks,
    //     HasLeaks: e.Leaks > 0,
    //     Offlines: e.Offlines,
    //     HasOfflines: e.Offlines > 0,
    //     Checks: e.Checks,
    //     HasChecks: e.Checks > 0,
    //     Alerts: e.Alerts,
    //     HasAlerts: e.Alerts > 0,
    //   }));
    //   setMonthlyEvents(mappedData);
    // });
  }, [date]);

  // const dateFullCellRender = (date) => {
  //   let className = "ant-picker-cell-inner";
  //   const dateMonth = date.$M + 1;
  //   const dateYear = date.$y;
  //   const dateDay = date.$D;
  //   const day = monthlyEvents?.find(
  //     (e) => e.Year === dateYear && e.Month === dateMonth && e.Day === dateDay,
  //   );
  //   if (day && day.Day === date.$D) {
  //     if (day.HasChecks) {
  //       className += " ant-picker-cell-check";
  //     }

  //     if (day.HasOfflines) {
  //       className += " ant-picker-cell-offline";
  //     }

  //     if (day.HasAlerts) {
  //       className += " ant-picker-cell-alert";
  //     }

  //     if (day.HasChecks && day.HasOfflines && day.HasAlerts) {
  //       className += " ant-picker-cell-all";
  //     }

  //     if (day.HasChecks && day.HasOfflines && !day.HasAlerts) {
  //       className += " ant-picker-cell-offline-and-check";
  //     }

  //     if (!day.HasChecks && day.HasOfflines && day.HasAlerts) {
  //       className += " ant-picker-cell-alert-and-offline";
  //     }

  //     if (day.HasChecks && !day.HasOfflines && day.HasAlerts) {
  //       className += " ant-picker-cell-alert-and-check";
  //     }
  //   }
  //   return <div className={className}>{dateDay}</div>;
  // };

  return (
    <div className="statistics-calendar mt-1 w-full">
      <Calendar
        fullscreen={false}
        mode="month"
        defaultValue={dayjs(new Date(date?.year, date?.m, date?.d))}
        value={calendarDate}
        // dateFullCellRender={dateFullCellRender}
        onPanelChange={onPanelChange}
        onSelect={(value) => {
          setCalendarDate(value);
        }}
      />
    </div>
  );
}

StatisticsCalendar.defaultProps = {
  setDate: () => {},
  date: {},
  // property: {},
  // floorId: null,
  // systemId: null,
};

StatisticsCalendar.propTypes = {
  setDate: PropTypes.func,
  date: PropTypes.object,
  // property: PropTypes.object,
  // floorId: PropTypes.string,
  // systemId: PropTypes.string,
};

export default StatisticsCalendar;
