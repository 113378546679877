import { useEffect, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Pagination, Skeleton } from "antd";
import Search from "antd/es/input/Search";
import { useSearchParams } from "react-router-dom";

import useProperties from "../../../../../api/hooks/useProperties";
import usePropertiesCount from "../../../../../api/hooks/usePropertiesCount";
import useAuth from "../../../../../hooks/useAuth";
import useWindowSize from "../../../../../hooks/useWindowSize";
import BackButton from "../../../../Shared/BackButton";
import FloorManagementPropertyCard from "./FloorManagementPropertyCard";

function FloorManagementPropertyList() {
  const window = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const { auth } = useAuth();
  const pageSize = auth?.rowsPerPage;
  // get search params from URl and format them as expected from the API
  const params = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { page } = params;

  const { data: totalPropertiesCount } = usePropertiesCount();
  const {
    data: properties,
    status: loadingStatus,
    refetch: getProperties,
  } = useProperties({
    pageSize,
    startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
    search: debouncedSearchQuery,
  });

  useEffect(() => {
    getProperties();
  }, [debouncedSearchQuery, page, getProperties]);

  const onSearch = (value) => {
    setSearchQuery(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  return (
    <div className="floor-management-property-list content-wrapper overflow-y-auto">
      <BackButton
        redirect={{ to: "/preferences/property-management" }}
        confirmation={false}
        className="self-start uppercase tracking-widest mr-4"
      />
      <div className="flex md:items-center mb-2 flex-col-reverse md:flex-row">
        <div className="flex-1 mt-6 md:mt-0 flex lg:items-center flex-col lg:flex-row">
          {window?.width > 768 && (
            <h1 className="page-title mb-0 !text-triple-blue">
              floor management
            </h1>
          )}
          {window?.width <= 768 && (
            <h1 className="page-title-mobile mb-0 text-center sm:text-left uppercase !text-triple-blue">
              floor management
            </h1>
          )}
          <div className="ml-auto inline-flex items-center">
            <Search
              className="w-full md:w-fit mr-4"
              placeholder="Search Properties"
              onSearch={onSearch}
              onChange={handleSearchChange}
              allowClear
              size="middle"
            />
          </div>
        </div>
      </div>

      {loadingStatus === "error" && (
        <div className="p-6 text-white text-opacity-80 text-center">
          <FrownOutlined className="text-xl mr-2" />
          Something went wrong
        </div>
      )}

      {loadingStatus === "loading" &&
        Array.from({ length: pageSize }, (value, index) => index).map((el) => (
          <Skeleton
            active
            paragraph={{
              rows: 3,
            }}
            key={el}
          />
        ))}

      {loadingStatus === "success" && properties?.length === 0 && (
        <div className="p-6 text-white text-opacity-80">
          <FrownOutlined className="text-xl mr-2" />
          We couldn`t find any matches
        </div>
      )}

      {loadingStatus === "success" && properties?.length > 0 && (
        <div className="sm:grid sm:grid-cols-2 lg:grid-cols-1 gap-x-6 lg:gap-x-0">
          {properties
            ?.sort(
              (firstElement, secondElement) =>
                firstElement.Id - secondElement.Id,
            )
            .filter((p) => p.Name !== "Default Property")
            .map((property) => (
              <div className="sm:my-2 my-4" key={property.Id}>
                <FloorManagementPropertyCard
                  objectProperty={property}
                  key={`${property.Id}_${property.Name}`}
                />
              </div>
            ))}
        </div>
      )}

      {loadingStatus === "success" &&
        properties?.length > 0 &&
        totalPropertiesCount > pageSize && (
          <Pagination
            defaultCurrent={1}
            pageSize={pageSize}
            total={totalPropertiesCount}
            current={Number(searchParams.get("page")) || 1}
            showSizeChanger={false}
            size={window?.width <= 576 && "small"}
            onChange={(page) => {
              setSearchParams({ page });
            }}
          />
        )}
    </div>
  );
}

export default FloorManagementPropertyList;
